import React from 'react';
import './styles/App.css';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import { IOSPage } from './Pages/iOS';
import { AndroidPage } from './Pages/Android';
import Root from './routes/root';
import ErrorPage from './error-page';
import { Home } from './Pages/Home';
import { PrivacyPolicy } from './Pages/Privacy Policy';
import { Footer } from './components/Footer';
import { Blog } from './Pages/Blog';
import { BackToTopButton } from './components/BackToTopButton';
import { BlogPost } from './Pages/BlogPost';
import { Provider } from 'react-redux';
import store from './store/store';
import { DownloadApp } from './Pages/DownloadApp';
import { Login } from './Pages/Login';
import { ProfilePage } from './Pages/Profile';
import { RefreshProvider } from './Context/RefreshContext';
import { AlbumsTorcida } from './Pages/AlbumsTorcida';
import { AlbumTorcidaDetail } from './Pages/AlbumTorcidaDetails';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<Root />} errorElement={<ErrorPage />} >
			<Route index element={<Home />} />
			<Route path="/ios" element={<IOSPage />} />
			<Route path="/android" element={<AndroidPage />} />
			<Route path="/download-app" element={<DownloadApp />} />
			<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route path="/blog" element={<Blog />} />
			<Route path="/blog/:id/:slug" element={<BlogPost />} />
			<Route path="/fotos-torcida" element={<AlbumsTorcida />} />
			<Route path="/fotos-torcida/:albumName" element={<AlbumTorcidaDetail />} />
			<Route path="/crz-admin" element={<Login />} />
			<Route path="/profile" element={<ProfilePage />} />
		</Route>
	)
);

function App() {

  return (
    <div className="bg-white">
			<ChakraProvider>
				<Provider store={store}>
					<RefreshProvider>
						<RouterProvider router={router} />
					</RefreshProvider>
					<BackToTopButton />
					<Footer />
				</Provider>
			</ChakraProvider>
    </div>
  );
}

export default App;
