
export const IOSPage = () => {
	return (
		<div className='flex flex-col p-8'>
		<div className='flex justify-center mb-20'>
			<h1 className='sm:text-5xl text-2xl text-lighter  rounded p-2'><b>CrzNews App</b>/ iOS Download</h1>
		</div>

		<div className='flex flex-col text-lighter items-start'>
			<h3 className='text-secondary'>iOS Link</h3>
			<p className='text-lg text-left mt-5'>
				Esta é a area em que você encontra o link do <b className='text-lighter rounded p-1'>CRZ NEWS</b> para download em iPhone ou iPad.
			</p>

			<h6 className="mt-5">Faça o download agora mesmo:</h6>

			<a className="mt-10 hover:text-secondary" href='https://apps.apple.com/br/app/crz-news/id1672761189?l=en' target="_blank" rel="noreferrer">Clique AQUI para download</a>

			<p className='text-lg text-left mt-10'>
				Muito obrigado pelo interesse! Esperamos que goste do aplicativo e que ele possa lhe ajudar a ficar mais informado sobre o <b className='text-secondary'>Cruzeiro</b>!
			</p>
		</div>
	</div>
	)
}
