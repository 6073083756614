import { useState } from 'react';
import { uploadToCrzBucket } from '../services/aws';

type MutationResult = {
  url: string;
  data: FormData
};

type MutationState = {
  isLoading: boolean;
  error: string;
};

const useMutation = () => {
  const [state, setState] = useState<MutationState>({ isLoading: false, error: '' });

  const fn = async (url: string, data: FormData) => {
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      await uploadToCrzBucket({ url, data })

    } catch (error: any) {
      setState(prev => ({ ...prev, error: error.message }));
    } finally {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  }

  return { mutate: fn, ...state };
}

export default useMutation;