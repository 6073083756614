import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { FaChevronLeft } from 'react-icons/fa';
import { ProfileCard } from '../../components/ProfileCard';
import { AuthorType } from '../../Types/BlogPosts';
import { ShareButtons } from '../../components/ShareButtons';
import { BlogAuthors } from '../../utils/BlogAuthors';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { RichText } from 'prismic-reactjs';
import './index.css';
import moment from 'moment';



export const BlogPost: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [author, setAuthor] = useState<AuthorType>({} as AuthorType);
  const [dataDePublicacao, setDataDePublicacao] = useState<string>();

  const blogPosts = useSelector((state: RootState) => state.blog.blogPosts);

  const blogPost = id ? blogPosts.find((post) => post.id === id) : undefined;

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (blogPost) {
      const matchedAuthor = BlogAuthors.find((author) => author.nome === blogPost.author);
      if (matchedAuthor) {
        setAuthor(matchedAuthor);
      }
    }
  }, [blogPost])

  useEffect(() => {
    setDataDePublicacao(moment(blogPost?.date).format('L'));
  },[blogPost]);


  if (!blogPost) {
    return (
      <>
        <div 
        className='flex text-white mt-8 cursor-pointer ml-2 text-sm items-center flex-row mb-4' 
        onClick={handleGoBack}
        >
          <FaChevronLeft />
          <p>Voltar</p>
        </div>
        <div className="flex flex-col justify-center items-center h-screen">
          <FaExclamationTriangle size={42} className="text-white" />
          <p className="text-white text-center mt-4">Postagem não foi encontrada ou não existe</p>
        </div>
      </>
    ); // Handle case when blog post is not found
  }

  return (
    <div className='bg-white w-full flex-col p-4'>
      <div 
        className='flex text-primary cursor-pointer ml-2 text-sm items-center flex-row mb-4' 
        onClick={handleGoBack}
      >
        <FaChevronLeft />
        <p>Voltar</p>
      </div>

      <div className='lg:px-60 md:px-30 sm:text-xs'>
        <h2 className="text-3xl mb-4 leading-10 text-start text-primary font-work-sans">
          {blogPost.title}
        </h2>

        <div className='bg-300'>
          <img className="object-cover rounded h-60 w-full" src={blogPost.image} alt='' />
        </div>

        <div className='flex flex-row justify-end italic gap-4 text-primary mb-6'>
          <p className="text-sm font-medium leading-5 font-work-sans">{`${blogPost.author}`}</p>
          <p className="text-sm font-medium leading-5 font-work-sans">{`${dataDePublicacao}`}</p>
        </div>

        <div className='text-light text-base tracking-wide leading-8 p-8'>
          <div className="custom-rich-text">
            <RichText render={blogPost.post} />
          </div>
        </div>

        <div className="flex justify-center text-xs gap-4 mt-8 flex-col items-center">
          <p className='text-light text-sm italic'>Gostou do texto? Compartilhe com seus amigos </p>
          <ShareButtons
            shareText={blogPost.title}
            blogPostId={blogPost.id.toString()}
            blogPostTitle={blogPost.title}
          />
        </div>

        <ProfileCard content={blogPost.post} profile={author}/>
      </div>
    </div>
  );
};
