import { Link } from "react-router-dom";
import { FaInstagram, FaLinkedinIn, FaGithub, FaEnvelope } from "react-icons/fa";

export const Home = () => {

	const photoUrls = [
		"https://res.cloudinary.com/dognkye6x/image/upload/v1691336422/B2B054D1-FFDE-461D-966D-A0AADE49932C_ms8yfv.jpg",
		"https://res.cloudinary.com/dognkye6x/image/upload/v1691336468/_MG_4958_qtyhx0.jpg",
		"https://res.cloudinary.com/dognkye6x/image/upload/v1691336039/IMG_8525_lb5fil.jpg",
    "https://res.cloudinary.com/dognkye6x/image/upload/v1687812795/41388375-f3eb-4011-b542-3db8d8e29dd4_vybe9x.jpg",
		"https://res.cloudinary.com/dognkye6x/image/upload/v1691335926/IMG_5795_osem8r.jpg",
		"https://res.cloudinary.com/dognkye6x/image/upload/v1691335914/IMG_1163_uwgdrb.jpg",
		"https://res.cloudinary.com/dognkye6x/image/upload/v1691336520/IMG_2156_oyfnpj.png",
		"https://res.cloudinary.com/dognkye6x/image/upload/v1684164835/IMG_0693_obyj0t.png",
		"https://res.cloudinary.com/dognkye6x/image/upload/v1684164789/IMG_1455_vg0bgq.png",
  ];

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 relative">
			<div className='flex flex-col p-8'>
				<div className='flex justify-center mb-20'>
					<h1 className='sm:text-5xl text-2xl text-lighter rounded p-2'><b>CrzNews App</b></h1>
				</div>

				<div className='flex flex-col text-lighter items-start'>
					<h3 className='text-secondary'>Sobre o App</h3>
					<p className='text-lg text-left mt-5'>
						O intuito deste aplicativo é aproximar o torcedor do <i className=''>Cruzeiro Esporte Clube</i>, ao clube. O <b className='text-lighter rounded p-1'>CRZ NEWS</b> reune em um mesmo lugar, as notícias sobre o <i className=''>Cruzeiro</i> vindas de diferentes portais.
					</p>

					<h6 className="text-lighter rounded-lg text-secondary mt-10 w-full">Blog da Torcida</h6>

					<p className='text-lg text-left mt-5'>
						O CRZ NEWS foi feito pelo torcedor e para o torcedor do <i className=''>Cruzeiro</i>.
					</p>
					<p className='text-lg text-left mb-5'>
						Contamos com um blog escrito por apaixonados pelo clube, que tem o intuito de informar, mas também trazer curiosidades sobre o time e sobre a rotina do <i>torcedor celeste</i>.
					</p>

					<p className='text-lg text-left mt-5'>
						Mas não para por aí!
						O aplicativo também disponibiliza as tabelas dos campeonatos:
							<ul className="flex flex-col sm:flex-row mt-5 mb-10 bg-lighter rounded p-3 justify-around">
								<li className="flex flex-col ml-5">
									<img className="w-24 h-24" src="https://res.cloudinary.com/dognkye6x/image/upload/v1674478268/campeonato_mineiro_logo_wqeua0.png" alt="logo campeonato mineiro"/>
									{/* <i>Campeonato Mneiro</i> */}
								</li>
								<li className="flex flex-col ml-5">
								<img className="w-24 h-24" src="https://res.cloudinary.com/dognkye6x/image/upload/v1674478268/logo-copa-do-brasil-2048_jui0gf.png" alt="logo copa do brasil"/>
									{/* <i>Copa do Brasil</i> */}
								</li>
								<li className="flex flex-col ml-5">
									<img className="w-24 h-24" src="https://res.cloudinary.com/dognkye6x/image/upload/v1674478268/Campeonato_Brasileiro_Se%CC%81rie_A_logo_mexngc.png" alt="logo campeonato brasileiro serie a"/>
									{/* <i>Campeonato Brasileiro</i> */}
								</li>
							</ul>
						Além das próximas partidas do <i className=''>Cabuloso</i> 🦊!!!
					</p>

					<h6 className="mt-5 text-lighter rounded-lg text-secondary w-full">Galeria de Fotos</h6>
					<p className='text-lg text-left mt-5'>
						Quem frequenta o setor Amarelo Superior sabe que eu sempre estou por ali tirando fotos da torcida. Agora estas fotos estão sendo disponibilizadas em nosso App. Caso eu tenha tirado alguma foto sua, baixe o app e confira se a foto está por lá.
					</p>

					<h6 className="mt-5 text-lighter rounded-lg text-secondary w-full">Canais do Youtube</h6>
					<p className='text-lg text-left mt-5'>
						No <b className='text-lighter rounded p-1'>CRZ NEWS</b> disponibilizamos os últimos cinco vídeos dos canais do <i className=''>Cruzeiro</i>, <i>Samuel Venâncio</i> e <i>Canal Seis a Um</i>
					</p>

					<p className='text-xs text-left mt-5'>
					<i>Disclaimer: </i>
						Eu criei este aplicativo sozinho e sem ajuda financeira. Por isso, no momento não consigo disponibilizar mais canais do youtube. Mas, caso encontre um parceiro, poderia fazê-lo.
					</p>

					<div className='m-10 border p-2 self-center'>
						<h3 className='text-secondary mt-2 text-center'>Não Perca Tempo!!!</h3>
						<div className='flex'>
							<Link to={`/download-app`}>
								<p className='text-lg text-left hover:text-secondary mt-5'>
									Clique aqui
								</p>
							</Link>
							<p className='text-lg text-left mt-5 m-1'>para baixar o App agora mesmo. Ou vá até a loja do seu aparelho e procure por <b>CRZ NEWS</b></p>
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-col p-8 bg-gray-100">
				<div className="text-center">
				<div className="mx-auto w-40 h-40 rounded-full mb-4">
					<img
						className="w-full h-full rounded-full object-cover object-center"
						style={{ objectPosition: 'center bottom' }}
						src="https://res.cloudinary.com/dognkye6x/image/upload/v1691333160/IMG_1552_ypr9ny.jpg"
						alt="foto do @_dancosta_"
					/>
				</div>


					<h3 className="text-primary text-lg font-semibold">
						Dan Costa
					</h3>
					<p className="text-gray-600 text-sm">Software Developer e Fotógrafo</p>
					<p className="text-gray-600 text-sm mb-4">Typescript | React Native | React | Node.ts | Java</p>
				</div>
				<p className="text-text text-sm mb-2">
					Olá, sou um <b>desenvolvedor de softwares</b> apaixonado por tecnologia e atualmente trabalho em uma FinTech de Belo Horizonte. Além da minha dedicação ao desenvolvimento de soluções móveis, tenho paixão pela fotografia e, é claro, pelo <i className="text-primary">Cruzeiro</i>. Foi essa paixão que me inspirou a criar o aplicativo <b className="text-primary">CRZ NEWS</b>.
				</p>

				<p className="text-text text-sm mb-2">
					O <b className="text-primary">CRZ NEWS</b> é uma criação minha, feita especialmente para pessoas que, assim como eu, amam o <i className="text-primary">Cruzeiro</i>. No site, você encontrará informações sobre como baixar o aplicativo em seu dispositivo. Além disso, compartilho as fotos que tiro e também oferecemos um blog criado por outros torcedores do <i className="text-primary">Cruzeiro</i>.

					Estou trabalhando incansavelmente, dia e noite, para trazer constantes inovações para o aplicativo. Meu objetivo é proporcionar uma experiência única para os torcedores, mantendo-os atualizados com as últimas notícias, fotos incríveis e informações interessantes sobre o clube.
				</p>

				<p className="text-text text-sm mb-2">
					Sinta-se à vontade para explorar o site, baixar o aplicativo e junte-se a essa comunidade de torcedores apaixonados pelo Cruzeiro. Espero que você aproveite o <b className="text-primary">CRZ NEWS</b> tanto quanto eu aproveitei criá-lo.
				</p>

				<h4 className="text-text text-lg font-semibold">Fotografia</h4>

				<p className="text-text text-sm mb-2">
					Como fotógrafo apaixonado por capturar momentos autênticos, meu trabalho é uma expressão vívida de minha paixão pelo fotojornalismo e pela arte de retratar o que vejo. Com um amor profundo pelos esportes, encontro inspiração ao fotografar tanto a paixão fervorosa dos torcedores quanto a intensidade dos atletas em pleno desempenho. Com experiência abrangente em uma variedade de modalidades, desde <span className="text-text font-semibold">futebol</span> e <span className="text-text font-semibold">vôlei</span> até <span className="text-text font-semibold">basquete</span>, <span className="text-text font-semibold">futebol</span> <span className="text-text font-semibold">americano</span>, <span className="text-text font-semibold">snowboarding</span>, <span className="text-text font-semibold">surf</span> e <span className="text-text font-semibold">wakeboarding</span>, busco sempre contar histórias visualmente cativantes e emocionantes. Cada clique é uma tentativa de capturar a essência única e o espírito inigualável presente em cada cena esportiva
				</p>

				<div className="md:flex md:flex-row md:justify-center mt-6 mb-4">
					<div className="mb-5 flex justify-around md:justify-center">
						<a href="https://www.instagram.com/_dancosta_/" target="_blank" rel="noreferrer">
							<FaInstagram className="w-8 h-8 text-primary hover:text-secondary md:mr-5"/>
						</a>

						<a href="https://www.linkedin.com/in/daniel-costa-dev/" target="_blank" rel="noreferrer">
							<FaLinkedinIn className="w-8 h-8 text-primary hover:text-secondary md:mr-5"/>
						</a>

						<a href="https://github.com/dancosta-fed" target="_blank" rel="noreferrer">
							<FaGithub className="w-8 h-8 text-primary hover:text-secondary md:mr-5"/>
						</a>
					</div>
			</div>

				<div className="grid grid-cols-1 md:grid-cols-3 gap-1 mt-4 mb-10">
        {photoUrls.map((url, index) => (
          <img
            key={index}
            className="w-full h-auto rounded shadow-md"
            src={url}
            alt={`foto ${index + 1}`}
          />
        ))}
      </div>

			<p className="text-text text-sm absolute mt-10 bottom-2">
				Se você tiver alguma dúvida, sugestão ou quiser entrar em contato comigo, envie um e-mail para <b className="text-primary">
					<a href="mailto:daniel@crznews.com.br">daniel@crznews.com.br</a>
				</b>.
			</p>
			</div>
		</div>
	)
}
