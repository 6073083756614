import React, { useEffect, useState } from 'react';
import { UploadButton } from '../../components/UploadButton';

export const ProfilePage: React.FC = () => {
	const [albumName, setAlbumName] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

	const handleAlbumNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setAlbumName(text);
  };

  useEffect(() => {
    if (success) {
      setAlbumName('');
    }
  }, [success]);


  return (
		<div className='flex p-10 flex-row mr-20'>
			<div className='flex flex-col p-10 items-center'>
				<h1 className='text-center'>Profile</h1>

				<input
					type="text"
					placeholder="Nome do album"
					value={albumName}
					onChange={handleAlbumNameChange}
					className='m-4 p-2 border-2 border-gray-300 rounded'
          required
				/>

				{
          !albumName ? (
              <p className='text-yellow-400 ml-4'>Preencha o nome do album</p>
          ) : (
              <div className='m-4 flex'>
                <UploadButton albumName={albumName} setSuccess={setSuccess} />
              </div>
          )
        }
			</div>
		</div>
  );
};