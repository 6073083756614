import { AuthorType } from "../Types/BlogPosts";

export const BlogAuthors: AuthorType[] = [
  {
    id: '0',
    nome: 'Todos',
    instagram: 'crznewsapp',
    twitter: 'crznewsapp',
    email: 'contato@crznews.com.br',
    photo: 'https://res.cloudinary.com/dognkye6x/image/upload/v1687699144/Blog_da_torcida_rpusq5.png'
  },
  {
    id: '1',
    nome: 'Dan Costa',
    instagram: '_dancosta_',
    twitter: '_dancosta_',
    email: 'daniel@crznews.com.br',
    photo: 'https://res.cloudinary.com/dognkye6x/image/upload/v1687812795/41388375-f3eb-4011-b542-3db8d8e29dd4_vybe9x.jpg'
  },
  {
    id: '2',
    nome: 'Mytla Sofiet',
    instagram: 'mytlasofiet',
    twitter: '',
    email: 'mytlaribeiro@outlook.com',
    photo: 'https://res.cloudinary.com/dognkye6x/image/upload/v1687540500/Captura_de_Tela_2023-06-23_a%CC%80s_14.14.18_ziqeoa.png'
  },
  {
    id: '3',
    nome: 'Laryssa Barbosa',
    instagram: '_llryssa_',
    twitter: 'llryssa_',
    email: 'contato.larybarbosa@gmail.com',
    photo: 'https://res.cloudinary.com/dognkye6x/image/upload/v1687811666/lary_rsffn7.jpg'
  },
  {
    id: '4',
    nome: 'Sabrina Sousa',
    instagram: 'sapsousa',
    twitter: 'sapsousa0',
    email: 'sabrinaabadia53@gmail.com',
    photo: 'https://res.cloudinary.com/dognkye6x/image/upload/v1687811670/sabrina_cmc49u.jpg'
  },
	{
    id: '4',
    nome: 'Thiago Bueno de Azevedo',
    instagram: 'teagoboeno',
    twitter: 'cecbueno',
    email: 'thiago.bueno0801@gmail.com',
    photo: 'https://res.cloudinary.com/dognkye6x/image/upload/v1687990450/thiago-profile_xyfeaw.jpg'
  }
]
