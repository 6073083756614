import React, { useEffect, useState } from 'react';
import { CrzBlogType } from '../../Types/BlogPosts';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import moment from 'moment';


export const BlogCard: React.FC<CrzBlogType> = ({ image, title, author, date, id, post, slug }) => {
  const [dataDePublicacao, setDataDePublicacao] = useState<string>();
  const slugTitle = slugify(title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });

  useEffect(() => {
    setDataDePublicacao(moment(date).format('L'));
  },[date]);
  
  return (
    <Link to={`/blog/${id}/${slug !== '' ? slug : slugTitle}`}>
      <div 
        className="bg-white rounded-lg shadow-md cursor-pointer p-4 mb-4 flex flex-col justify-between transform transition-all hover:scale-105 h-full"
      >
        <img src={image} alt={title} className="w-full h-60 object-cover rounded-t-lg" />
        <div className="p-4">
          <h2 className="text-lg text-primary font-bold">{title}</h2>
          <p className="text-gray-500 text-sm mb-2">Por {author}</p>
          <p className="text-gray-500 text-sm">{dataDePublicacao}</p>
        </div>
      </div>
    </Link>
  );
};


