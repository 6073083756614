import { FaInstagram, FaLinkedinIn, FaGithub, FaEnvelope } from "react-icons/fa";

export const Footer = () => {
	return (
		<>
			<div className="flex flex-col text-primary mt-10 mb-15 z-10">
				<div className="md:flex md:flex-row md:justify-center">
					<div className="mb-5 flex justify-around md:justify-center">
						<a href="https://www.instagram.com/_dancosta_/" target="_blank" rel="noreferrer">
							<FaInstagram className="w-8 h-8 hover:text-secondary md:mr-5"/>
						</a>

						<a href="https://www.linkedin.com/in/daniel-costa-dev/" target="_blank" rel="noreferrer">
							<FaLinkedinIn className="w-8 h-8 hover:text-secondary md:mr-5"/>
						</a>

						<a href="https://github.com/dancosta-fed" target="_blank" rel="noreferrer">
							<FaGithub className="w-8 h-8 hover:text-secondary md:mr-5"/>
						</a>

						<a href="mailto:contato@crznews.com.br" className="hover:text-secondary">
							<FaEnvelope className="w-8 h-8 hover:text-secondary md:mr-5"/>
						</a>
					</div>

					{/* <a href="mailto:dancosta.id@gmail.com" className="hover:text-secondary">dancosta.id@gmail.com</a> */}
				</div>
				<div className="mb-10 flex justify-center">
					<h2 className="text-xs">
						Criado por <i className=""> Daniel Costa</i>
					</h2>
				</div>
			</div>
		</>
	)
}
