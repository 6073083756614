import { Text } from "@chakra-ui/react";
import React from "react";

interface ErrorTextProps {
  children: React.ReactNode;
  color?: string;
  fontSize?: string;
}

export const ErrorText = ({children, color, fontSize}: ErrorTextProps) => {
  return (
    <Text color={color} fontSize={fontSize}>
      { children }
    </Text>
  );
};