import React, { useEffect, useState } from 'react'
import { BlogCard } from '../../components/BlogCards';
import { getAllBlogs } from '../../services/prismic';
import { useDispatch } from 'react-redux';
import { CrzBlogType } from '../../Types/BlogPosts';
import { setBlogPostsArray } from '../../store/BlogSlice';


export const Blog = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [blogPosts, setBlogPosts] = useState<any[]>();


  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredPosts = blogPosts?.map((post: any) => {
    let slug: string;
    const blogData = post.data;

    if (post.slugs && post.slugs.length > 0) {
      slug = post.slugs[0];
      return {
        id: post.id,
        image: blogData.cover_image.url,
        title: blogData.post_title[0].text,
        author: blogData.author_info[0].text,
        date: blogData.publish_date,
        post: blogData.post,
        slug: slug
      };
    } else {
      console.log('No slug found');
      return {
        id: post.id,
        image: blogData.cover_image.url,
        title: blogData.post_title[0].text,
        author: blogData.author_info[0].text,
        date: blogData.publish_date,
        post: blogData.post,
        slug: ''
      };
    }

  }).filter((post: any) =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.author.toLowerCase().includes(searchTerm.toLowerCase())
  );


  useEffect(() => {
    const fetchData = async () => {
      try {
        const allPosts = await getAllBlogs();
        const sortedPosts = allPosts?.sort((a: any, b: any) => {
          const dateA = new Date(a.data.publish_date);
          const dateB = new Date(b.data.publish_date);
          return dateB.getTime() - dateA.getTime();
        });
        setBlogPosts(sortedPosts)
      } catch (error) {
        console.error('Error fetching blog posts from Prismic:', error);
      }
    };

    fetchData();

    dispatch(setBlogPostsArray(blogPosts as CrzBlogType[]));
  }, [blogPosts, dispatch]);

  useEffect(() => {
    dispatch(setBlogPostsArray(filteredPosts as CrzBlogType[]));
  }, [filteredPosts, dispatch]);

  return (
    <>
      <div className="flex flex-col items-center mx-auto z-10">

        <div className='bg-500 items-center'>
          <img className="object-fit h-60 " src='https://res.cloudinary.com/dognkye6x/image/upload/v1687814481/Blog_da_torcida__1_-removebg-preview_acvhfd.png' alt='' />
        </div>
        <input
          type="text"
          placeholder="Busque por autor ou título..."
          className="border rounded p-2 w-2/3 sm:flex mb-4 focus:outline-none focus:bg-gray-300 text-primary"
          value={searchTerm}
          onChange={handleSearch}
        />

      </div>
				{
					filteredPosts?.length === 0 ? (
						<div className="justify-center m-10">
							<p className="text-center text-gray-300 text-center">Nenhum post ou autor encontrado.</p>
						</div>
        	) : (
								<div className="p-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
									{
										filteredPosts?.map((item: any) => (
											<BlogCard
												key={item.id}
												id={item.id}
												image={item.image}
												title={item.title}
												author={item.author}
												date={item.date}
												slug={item.slug}
												post={item.post}
											/>
										))
									}
								</div>
        			)
				}
    </>
  )
}
