import { useEffect, useRef, useState, MouseEvent } from "react";
import { Outlet, Link } from "react-router-dom";
import { useWindowSize } from 'react-use';
import { FaBars } from 'react-icons/fa';

export default function Root() {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const { width } = useWindowSize();
	const menuRef = useRef<HTMLDivElement>(null);


  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setMenuOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside as any);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside as any);
		};
	}, []);



  return (
    <div className="flex flex-col">
      <div
				id="sidebar"
				className="z-20 flex flex-row justify-between items-center text-primary flex-grow justify-between"
			>
				<Link to={`/`} className="flex sm:ml-10">
					<img
						src="https://res.cloudinary.com/dognkye6x/image/upload/v1674416777/crznews-removebg_t2pgdw.png"
						alt="crzNews app logo"
						className="w-20 rounded"
					/>
				</Link>

				<div className="flex flex-col sm:flex-row justify-between items-center">
					<div className="flex flex-row relative" ref={menuRef}>
						{width <= 767 && (
							<>
								<button
									type="button"
									onClick={toggleMenu}
									className="
										z-11
										rounded
										p-6
										cursor-pointer
										hover:text-secondary
										focus:outline-none
										sm:hidden
										text-primary
									"
								>
									<FaBars />
								</button>

								{width <= 700 && isMenuOpen && (
									<div className="z-11 absolute top-16 right-0 bg-white py-2 px-4 shadow rounded">
										<Link
											to={`/`}
											className="
												block mb-2
												p-2
												cursor-pointer
												hover:text-secondary
											"
										>
											Sobre
										</Link>

										<Link
											to={`/blog`}
											className="
												block mb-2
												p-2
												cursor-pointer
												hover:text-secondary
											"
										>
											Blog
										</Link>

										<Link
											to={`/download-app`}
											className="
												block mb-2
												p-2
												cursor-pointer
												hover:text-secondary
												"
										>
											Baixe o App
										</Link>

										<Link
											to={`/fotos-torcida`}
											className="
												block mb-2
												p-2
												cursor-pointer
												hover:text-secondary
												"
										>
											Fotos Torcida
										</Link>

										<Link
											to={`/privacy-policy`}
											className="
												block mb-2
												p-2
												cursor-pointer
												hover:text-secondary
											"
										>
											Politica de Privacidade
										</Link>
									</div>
								)}
							</>
						)}

						{width > 641 && (
							<div className="flex text-primary flex-row space-x-6">
								<Link
									to={`/`}
									className="
										rounded
										px-1
										cursor-pointer
										hover:text-secondary
									"
								>
									Sobre
								</Link>

								<Link
									to={`/blog`}
									className="
										rounded
										px-1
										cursor-pointer
										hover:text-secondary
									"
								>
									Blog
								</Link>

								<Link
									to={`/download-app`}
									className="
										rounded
										px-1
										cursor-pointer
										hover:text-secondary
									"
								>
									Baixe o App
								</Link>

								<Link
									to={`/fotos-torcida`}
									className="
										rounded
										px-1
										cursor-pointer
										hover:text-secondary
									"
								>
									Fotos Torcida
								</Link>

								<Link
									to={`/privacy-policy`}
									className="
										rounded
										px-1
										cursor-pointer
										hover:text-secondary
									"
								>
									Politica de Privacidade
								</Link>
							</div>
						)}
					</div>
				</div>
      </div>

      <div className="flex flex-col">
			<div
				id="homepage"
				className="z-10 bg-primary w-screen"
			>
				<div className="sm:text-2xl w-full items-center justify-between flex-col">
					<Outlet />
				</div>
			</div>

      </div>
    </div>
  );
}
