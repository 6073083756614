import React, { createContext, useContext, useState, ReactNode } from 'react';

export type RefreshContextType = {
  refresh: boolean;
  triggerRefresh: () => void;
};

const defaultContextValue: RefreshContextType = {
  refresh: false,
  triggerRefresh: () => {},
};

export const RefreshContext = createContext<RefreshContextType>(defaultContextValue);

type RefreshProviderProps = {
  children: ReactNode;
};

export const RefreshProvider = ({ children }: RefreshProviderProps) => {
  const [refresh, setRefresh] = useState(false);

  const triggerRefresh = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };

  const contextValue: RefreshContextType = {
    refresh,
    triggerRefresh,
  };

  return (
    <RefreshContext.Provider value={contextValue}>
      {children}
    </RefreshContext.Provider>
  );
};

export const useRefresh = () => {
  const context = useContext(RefreshContext);
  if (!context) {
    throw new Error('useRefresh must be used within a RefreshProvider');
  }
  return context;
};
