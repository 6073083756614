import { FaWhatsapp, FaTwitter, FaEnvelope } from 'react-icons/fa';
import slugify from 'slugify';

type ShareButtonsProps = {
  shareText: string;
  blogPostId: string;
  blogPostTitle: string;
};

export const ShareButtons: React.FC<ShareButtonsProps> = ({
  shareText,
  blogPostId,
  blogPostTitle,
}) => {
  const slug = slugify(blogPostTitle, { lower: true, remove: /[*+~.()'"!:@?]/g });

  const handleShareWhatsapp = () => {
    const encodedText = encodeURIComponent(`${shareText}\n\n${window.location.origin}/blog/${blogPostId}/${slug}`);
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedText}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleShareTwitter = () => {
    const encodedText = encodeURIComponent(`${shareText}\n\n${window.location.origin}/blog/${blogPostId}/${slug}`);
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedText}`;
    window.open(twitterUrl, '_blank');
  };

  return (
    <div>
      <button
        type="button"
        className="text-green-500 mr-2 hover:text-primary"
        onClick={handleShareWhatsapp}
      >
        <FaWhatsapp size={24} />
      </button>
      <button
        type="button"
        className="text-blue-500 mr-2 hover:text-primary"
        onClick={handleShareTwitter}
      >
        <FaTwitter size={24} />
      </button>
      {/* <a
        href={`mailto:?subject=Check out this post&body=${encodeURIComponent(
          `${shareText}\n\n${window.location.origin}/blog/${blogPostId}/${slug}`
        )}`}
        className="text-primary"
      >
        <FaEnvelope size={24} />
      </a> */}
    </div>
  );
};
