import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import './index.css';

export const BackToTopButton: React.FC = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setShowButton(scrollPosition > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      className={`fixed right-4 bottom-4 p-3 hover:text-secondary z-10 rounded-full bg-white text-white ${
        showButton ? 'opacity-100' : 'opacity-0'
      }`}
      onClick={scrollToTop}
    >
      <FaArrowUp color='#185B9F' />
    </button>
  );
};

