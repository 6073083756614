import './styles/App.css';
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className="bg-primary App-header h-screen">
      <div
				className="flex-col container text-primary justify-center bg-white w-auto p-4 rounded"
			>
				<h1 className='font-boldtext-palestraVerde'>Oops!</h1>
				<p>Me desculpe, ocorreu um erro na busca.</p>
				<p>
					<i>A página está com um status de
						 <strong className='text-palestraVermelho'> { error.statusText || error.message } </strong>
					</i>
				</p>
			</div>
    </div>
  );
}
