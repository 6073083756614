import { configureStore } from '@reduxjs/toolkit';
import blogReducer from './BlogSlice';

// Define the root state type
export type RootState = ReturnType<typeof store.getState>;

// Create the store
const store = configureStore({
  reducer: {
    blog: blogReducer,
  },
});

export default store;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
