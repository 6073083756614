import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CrzBlogType } from '../Types/BlogPosts';

// Define the initial state interface
interface BlogState {
  blogPosts: CrzBlogType[];
}

// Define the initial state
const initialState: BlogState = {
  blogPosts: [],
};

// Create a slice of the store
const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setBlogPostsArray: (state, action: PayloadAction<CrzBlogType[]>) => {
      state.blogPosts = action.payload;
    },
  },
});

// Export the action creator
export const { setBlogPostsArray } = blogSlice.actions;

export default blogSlice.reducer;
