import { Card, CardBody, Text, Link } from "@chakra-ui/react";
import { CopyableText } from "../CopyButton";

export const VakinhaCard = () => {

  return(
    <Card m='10px' alignItems='center' variant={'filled'} size={'sm'}>
      <CardBody padding={'5px'}>
        <div className='flex flex-col'>
          <Text fontSize="lg" className='text-text'>
            Estou fazendo uma vakinha para arrecadar fundos para a compra de uma nova lente fotográfica. Se você gostou das fotos e quer me ajudar, clique no botão abaixo e faça sua doação. Obrigado!
          </Text>

          <Link
            href="https://www.vakinha.com.br/4944269"
            target="_blank"
            rel="noreferrer"
            color='#185B9F'
            _hover={{ color: '#F2BC44 ' }}
            ml={2}
          >
            Link para vakinha
          </Link>
        </div>

        <span className="flex flex-row">
          <Text fontSize="lg" className='text-text' mr={2}>
            Ou doe via pix 
          </Text>
          <CopyableText color={'#185B9F'} textToCopy={"4944269@vakinha.com.br"} />
        </span>

      </CardBody>
    </Card>
  )
};