import { awsImageUpload, baseURL } from "./api";

const URL = {
  //dev: 'http://localhost:4343',
  dev: 'https://crzserver.com.br',
  // prod: 'http://54.207.37.72:4343',
};

export type UploadImageAWSInterface = {
  url: string,
  data: FormData,
}

export type ImageInterface = {
  url: string,
  lastModified: string,
}

export type AlbumInfoType = {
  name: string,
  creationDate: string
}

export const getAwsUrl = async (albumName: string) => {
  try {
    const { data } = await awsImageUpload({
      method: 'GET',
      url: `${baseURL}/s3Url/${albumName}`,
    });

    return { url: data.url };
  } catch (error: any) {
    console.error('AWS Error in getAwsUrl:', error);
    return { error: error.message };
  }
}


export const uploadToCrzBucket = async ({ url, data }: UploadImageAWSInterface) => {
  try {

    const response = await awsImageUpload({
      method: 'PUT',
      url: url,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  } catch (error) {
    console.error('AWS Error in generateUploadUrlAWS:', error);
    throw error;
  }
};

export const getDirectories = async () => {
  try {
    const { data } = await awsImageUpload({
      method: 'GET',
      url: `${URL.dev}/directories`
    });

    const directoriesWithCovers = await Promise.all(
      data.directories.map(async (album: AlbumInfoType) => {
        const images: ImageInterface[] = await getAlbumImages(album.name);
        const coverImage = images.find((image: ImageInterface) => image.url.includes('_cover'));
        return {
          albumInfo: album,
          coverImage: coverImage?.url || '',
        };
      })
    );

    return directoriesWithCovers;
  } catch (error: any) {
    console.error('AWS Error in getDirectories:', error);
    return [];
  }
}

export const getAlbumImages = async (albumName: string) => {
  try {
    const response = await awsImageUpload({
      method: 'GET',
      url: `${URL.dev}/fotos-torcida/${albumName}`,
    });

    return response.data.presignedUrls;
  } catch (error) {
    console.error('AWS Error in getAlbumContents:', error);
    return [];
  }
};
