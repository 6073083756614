import Prismic from 'prismic-javascript';

const apiEndpoint = 'https://crzblog.cdn.prismic.io/api/v2';
const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6ImNyemJsb2ctMjgxOGQ3MTgtMzk2Zi00NDMxLTg2ZTYtZWU5ZDFiMTMwZjllXzQiLCJkYXRlIjoxNjg3MTMxMzE3LCJkb21haW4iOiJjcnpibG9nIiwiaWF0IjoxNjg3MTMxMzE3fQ.dRqOk9xKQuFg0s0xvPtuBcAMUrNrkPDRTKy_bpDZf5c';

export const getAllBlogs = async () => {
  try {
    const api = await Prismic.api(apiEndpoint, { accessToken });
    const response = await api.query(Prismic.Predicates.at('document.type', 'crz_blog'));
    return response.results;
  } catch (error) {
    console.error('Error fetching Prismic data:', error);
    return [];
  }
};