import React from 'react';
import { Text, Tooltip, useClipboard, useToast } from '@chakra-ui/react';

type CopyableTextProps = {
  textToCopy: string;
  color: string;
};

export const CopyableText = ({ textToCopy, color }: CopyableTextProps) => {

  const { hasCopied, onCopy } = useClipboard(textToCopy);
  const toast = useToast();

  const handleCopy = () => {
    onCopy();
    toast({
      title: 'Chave copiada!',
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
  };

  return (
    <Tooltip label={hasCopied ? 'Copiada!' : 'Clique para copiar'} closeOnClick={false}>
      <Text
        fontSize="lg"
        cursor="pointer"
        onClick={handleCopy}
        _hover={{ color: '#F2BC44' }}
        color={color}
      >
        {textToCopy}
      </Text>
    </Tooltip>
  );
};

