import React, { useEffect, useState } from 'react';
import { AlbumInfoType, getDirectories } from '../../services/aws';
import {
  Box,
  Image,
  Grid,
  GridItem,
  Text,
  Card,
  CardBody,
  Link
} from '@chakra-ui/react';
import moment from 'moment';
import { VakinhaCard } from '../../components/VakinhaCard';

interface Album {
  albumInfo: AlbumInfoType;
  coverImage?: string;
}

export const AlbumsTorcida = () => {
  const placeholderImage = 'https://res.cloudinary.com/dognkye6x/image/upload/v1684163110/crznewsfox_maxluc.png';
  const [albums, setAlbums] = useState<Album[]>([]);

  useEffect(() => {
    const fetchAlbums = async () => {
      const currentTime = new Date().getTime();
  
      // Check if albums data is already saved in localStorage
      const cachedAlbums = localStorage.getItem('albums');
      const cachedTime = localStorage.getItem('albumsTimestamp');
  
      if (cachedAlbums && cachedTime) {
        const timeElapsed = currentTime - parseInt(cachedTime, 10);
  
        // Check if the data is older than 24 hours (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
        if (timeElapsed < 2 * 60 * 60 * 1000) {
          setAlbums(JSON.parse(cachedAlbums));
          return;
        } else {
          // Clear the local storage if the data is older than 24 hours
          localStorage.removeItem('albums');
          localStorage.removeItem('albumsTimestamp');
        }
      }
  
      // Fetch albums from the server
      const directoriesWithCovers: Album[] = await getDirectories();

      const sortedDirectories = directoriesWithCovers.sort((a, b) => {
        return new Date(b.albumInfo.creationDate).getTime() - new Date(a.albumInfo.creationDate).getTime();
      });
      
      // Save the albums to state and localStorage
      setAlbums(sortedDirectories);
      localStorage.setItem('albums', JSON.stringify(sortedDirectories));
      localStorage.setItem('albumsTimestamp', currentTime.toString());
    }
  
    fetchAlbums();
  }, []);
  
  const formatAlbumName = (name: string): string => {
    return name.split('_').join(' ');
  }  
  
  return (
    <Box p={4}>
      <div className='flex flex-row'>
        <Text fontSize="2xl" mb={4} className='text-white'>
          Fotos da Torcida por Dan Costa |
        </Text>
        <Link
          href="https://www.instagram.com/_dancosta_"
          target="_blank"
          rel="noreferrer"
          color='#FFF'
          _hover={{ color: '#F2BC44 ' }}
          ml={2}
        >
          @_dancosta_
        </Link>
      </div>

      <VakinhaCard />

      <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={2}>
        {albums.map((album) => (
          <GridItem key={album.albumInfo.name}>
            <Card className='magnify' style={{ height: '100%' }}>
              <CardBody padding={'5px'}>
                <a className="photohypertag" href={`/fotos-torcida/${album.albumInfo.name}`}>
                  {album.coverImage ? (
                    <Image
                      src={album.coverImage}
                      alt={`${album.albumInfo.name}_coverphoto`}
                      borderRadius="md"
                      mb={4}
                    />
                  ) : (
                    <Box
                      w="100%"
                      borderRadius="md"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Image src={placeholderImage} alt="placeholder"/>
                    </Box>
                  )}
                  <div>
                    <Text fontSize="lg" className='text-primary'>
                      {formatAlbumName(album.albumInfo.name)}
                    </Text>
                    <Text fontSize="xs" className='text-primary'>
                      {moment(album.albumInfo.creationDate).format('DD/MM/YYYY')}
                    </Text>
                  </div>
                </a>
              </CardBody>
            </Card>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};
