import React, { useEffect, useState } from 'react';
import './index.css';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../Config/firebase';
import { useNavigate } from 'react-router';

export const Login: React.FC = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const handleLogin = async () => {
		setIsLoading(true);
		setIsDisabled(true);
		await signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
				const user = userCredential.user;
				localStorage.setItem('user', JSON.stringify(user));
				 navigate("/profile")
		})
		.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				console.log(errorCode, errorMessage);
				setError(errorMessage);
		})
		.finally(() => {
			setIsLoading(false);
			setIsDisabled(false);
		});
	};

	useEffect(() => {
		const emailRegex = /\S+@\S+\.\S+/;

		if (emailRegex.test(email) && password) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [email, password]);

	return (
		<div className='containerBox'>
			<h1>CRZ NEWS</h1>
			<input
				type="text"
				placeholder="Email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				className='input'
			/>
			<input
				type="password"
				placeholder="Password"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				className='input'
			/>
			<button
				disabled={isDisabled}
				className="button"
				onClick={handleLogin}
			>
				{ isLoading ? 'Login in...' : 'Login'}
			</button>
			<p className='text-red-500 mt-2'>
				{error}
			</p>
		</div>
	);
};
