import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAlbumImages } from '../../services/aws';
import {
  Box,
  Image,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Spinner,
  Text,
  Link,
} from '@chakra-ui/react';
import Slider from 'react-slick';
import { VakinhaCard } from '../../components/VakinhaCard';
import { FaDownload } from 'react-icons/fa';

interface Photo {
  url: string;
  lastModified: string;
}

export const AlbumTorcidaDetail = () => {
  const { albumName = '' } = useParams<{ albumName: string }>();
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(true);

  const openPhoto = (imageIndex: number) => {
    setCurrentPhotoIndex(imageIndex);
    onOpen();
  };

  const sliderSettings = {
    initialSlide: currentPhotoIndex,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  };

  const formatAlbumName = (name: string): string => {
    return name.split('_').join(' ');
  }

  const handleDownload = async (photo: Photo) => {
    const newWindow = window.open(photo.url, '_blank');
  
    if (newWindow) {
      newWindow.onload = () => {
        const link = newWindow.document.createElement('a');
        link.href = photo.url;
        link.setAttribute('download', `${new Date().getSeconds}.jpg`);
        newWindow.document.body.appendChild(link);
        link.click();
        newWindow.document.body.removeChild(link);
        newWindow.close();
      };
    }
  };
  

  useEffect(() => {
    const fetchPhotos = async () => {
      setLoading(true);
      
      // Check if photos are already saved in localStorage
      const cachedPhotos = localStorage.getItem(`photos_${albumName}`);
      if (cachedPhotos) {
        setPhotos(JSON.parse(cachedPhotos));
        setLoading(false);
        return;
      }
      
      // Fetch photos from the server
      const albumPhotos: Photo[] = await getAlbumImages(albumName);
      albumPhotos.sort((a, b) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime());
      
      // Save the photos to state and localStorage
      setPhotos(albumPhotos);
      localStorage.setItem(`photos_${albumName}`, JSON.stringify(albumPhotos));

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };

    fetchPhotos();
  }, [albumName]);

  return (
    <>
      {loading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bg="rgba(0, 0, 0, 0.8)"
          zIndex={9999}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Spinner size="xl" color="white" />
          <Text mt={4} color="white" fontSize="lg">
            carregando as fotos...
          </Text>
        </Box>
      )}
      <Card backgroundColor='transparent'>
        <CardHeader color='white'>{
            formatAlbumName(albumName)}
        </CardHeader>
        
        <div className='flex flex-row'>
          <Text fontSize="2xl" mb={4} className='text-white'>
            Fotos da Torcida por Dan Costa |
          </Text>
          <Link
              href="https://www.instagram.com/_dancosta_"
              target="_blank"
              rel="noreferrer"
              color='#FFF'
              _hover={{ color: '#F2BC44 ' }}
              ml={2}
            >
              @_dancosta_
          </Link>
        </div>
        <VakinhaCard />
        <CardBody>
          <Box
            padding={4}
            w="100%"
            mx="auto"
            sx={{ columnCount: [1, 2, 3], columnGap: "8px" }}
          >
            {photos.map((photo, index) => (
              <Image
                key={index}
                w="100%"
                borderRadius="xl"
                mb={2}
                src={photo.url}
                alt="Alt"
                onClick={() => openPhoto(index)}
                style={{ cursor: 'pointer' }}
              />
            ))}
          </Box>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent
          backgroundColor="transparent"
          maxW={["90%", "80%", "70%", "60%"]}
          margin="auto"
          borderRadius="lg"
          boxShadow="lg"
        >
          <ModalCloseButton color="white" />
          <ModalBody padding={0}>
            <Slider {...sliderSettings}>
              {photos.map((photo, index) => (
                <Box className="relative" key={index}>
                  <div onClick={() => handleDownload(photo)} className="absolute top-1 right-2 ml-2 py-2 flex justify-center items-center cursor-pointer">
                    <Text className="mr-1" color="white" fontSize="lg" textAlign="center">
                      Baixar imagem
                    </Text>
                    <FaDownload className="" color="#FFF" onClick={() => console.log('Download clicked')} />
                  </div>
                  <Image
                    src={photo.url}
                    alt="Alt"
                    borderRadius="lg"
                    objectFit="contain"
                    width="100%"
                    maxH="80vh"
                  />
                </Box>
              ))}
            </Slider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
