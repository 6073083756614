
export const PrivacyPolicy = () => {
	return (
		<div className='flex flex-col p-8'>
		<div className='flex justify-center mb-20'>
			<h1 className='sm:text-5xl text-2xl text-lighter rounded p-2'><b>CrzNews App</b>/ Política de Privacidade</h1>
		</div>

		<fieldset>
			<div className='flex flex-col text-lighter items-start'>
				<h3 className='text-secondary mb-15'>Nossa Política de Privacidade</h3>
				<ul className='mt-15 p-4'>
					<li className="list-[upper-roman] mb-3">
						<p className='text-start'>
							Nosso aplicativo, <i className="text-secondary">CRZ News</i>, está comprometido em proteger a privacidade de nossos usuários. Nós não coletamos nenhum dado pessoal de nossos usuários.
						</p>
					</li>

					<li className="list-[upper-roman] mb-3">
						<p className='text-start'>
							O aplicativo apenas exibe artigos de notícias que não são escritos pelo proprietário do aplicativo. Esses artigos são provenientes de diversas fontes de notícias e não exigem qualquer informação do usuário. O aplicativo não acessa nenhum dado ou informação do dispositivo do usuário.
						</p>
					</li>
				</ul>

				<h3 className='text-secondary mb-15'>Sobre o Youtube em nosso App</h3>
				<ul className='mt-15 p-4'>
					<li className="list-[upper-roman] mb-3">
						<p className='text-start'>
							Este aplicativo utiliza a API do YouTube Services, que é fornecida pelo Google. Ao usar o Serviço, você concorda com os Termos de Serviço do YouTube Services e com a Política de Privacidade do Google. Você pode encontrar esses termos e políticas em seus respectivos sites abaixo.
						</p>
					</li>
				</ul>


					<h3 className='text-secondary mb-15'>Anúncios de terceiros</h3>
					<ul className='mt-15 p-4'>
					<li className="list-[upper-roman] mb-3">
						<p className='text-start'>
							Permitimos que terceiros exibam anúncios em nosso Serviço. Esses anunciantes podem usar tecnologias de rastreamento para coletar informações sobre os usuários que visualizam ou interagem com seus anúncios. Nós não fornecemos nenhuma informação pessoal a esses anunciantes, exceto conforme descrito nesta Política de Privacidade.
						</p>
					</li>
				</ul>

				<h3 className='text-secondary mb-15'>Links para sites de terceiros</h3>
					<ul className='mt-15 p-4'>
					<li className="list-[upper-roman] mb-3">
						<p className='text-start'>
							Nosso Serviço pode conter links para sites de terceiros que não são operados por nós. Não temos controle sobre esses sites e não somos responsáveis por suas práticas de privacidade. Recomendamos que você revise as políticas de privacidade de qualquer site que visite.
						</p>
					</li>
				</ul>

				<h3 className='text-secondary mb-15'>Alterações nesta Política de Privacidade</h3>
					<ul className='mt-15 p-4'>
					<li className="list-[upper-roman] mb-3">
						<p className='text-start'>
							Reservamos o direito de atualizar essa Política de Privacidade a qualquer momento. Se fizermos quaisquer alterações em nossa política, postaremos a política atualizada nesta página. Se você tiver alguma dúvida ou preocupação com a nossa Política de Privacidade, entre em contato conosco em <a className="hover:text-secondary" href="mailTo:contato@crznews.com.br">contato@crznews.com.br</a>.
						</p>
					</li>
				</ul>

				<div className="flex flex-row w-full items-center justify-center">
					<a className="text-secondary hover:text-lighter mr-4" target="blank" href="http://www.google.com/policies/privacy">Política de Privacidade do Google</a>
					<a className="text-secondary hover:text-lighter" target="blank" href="https://www.youtube.com/t/terms">Termos de Serviço do YouTube</a>
				</div>

			</div>
		</fieldset>
	</div>
	)
}
