import { Box, Button, Input } from '@chakra-ui/react';
import { useState } from 'react';
import useMutation from '../../hooks/useMutation';
import { ErrorText } from '../ErrorText';
import { getAwsUrl } from '../../services/aws';
import Swal from 'sweetalert2';

const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];

type UploadButtonProps = {
  albumName: string;
  setSuccess: (args: boolean) => void;
};

export const UploadButton = ({ albumName, setSuccess }: UploadButtonProps) => {
  const [error, setInvalidFileType] = useState<string>('');
  const [urlError, setUrlError] = useState<string>('');

  const {
    mutate: uploadImage,
    isLoading: uploading,
    error: uploadError
  } = useMutation();

  const onHandleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidFileType('');
    const fileList = event.target.files;
  
    if (!fileList) {
      return;
    }
  
    const form = new FormData();
  
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
  
      if (!validImageTypes.find((type: string) => type === file.type)) {
        setInvalidFileType('O arquivo deve ser jpg/png');
        return;
      }
  
      form.append('images', file); 
    }

    try {
      const { url, error: awsError } = await getAwsUrl(albumName);

      if (awsError) {
        console.error('Error getting the url from AWS:', awsError);
        setUrlError('Erro ao obter a URL do AWS');
        return;
      }
    
      await uploadImage(url, form);

      Swal.fire({
        icon: 'success',
        title: 'Upload realizado com sucesso',
        showConfirmButton: false,
        timer: 1500
      });

      setSuccess(true);

    } catch (error) {
      console.error('Error in getAwsUrl:', error);
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro ao fazer o upload',
        showConfirmButton: false,
        timer: 1500
      });
    }

  };

  return (
    <Box>
      <Input id='imageInput' name='images' type='file' multiple hidden onChange={onHandleUpload} />
      <Button
        colorScheme='whitesmoke'
        color='white'
        variant='outline'
        mb='4'
        mt='4'
        cursor='pointer'
        as='label'
        htmlFor='imageInput'
        isLoading={uploading}
      >
        Upload
      </Button>

      {error && (
        <ErrorText color='red.400' fontSize='lg'>
          {error}
        </ErrorText>
      )}

      {uploadError && (
        <ErrorText color='red.400' fontSize='lg'>
          {uploadError}
        </ErrorText>
      )}

      {urlError && (
        <ErrorText color='red.400' fontSize='lg'>
          {urlError}
        </ErrorText>
      )}
    </Box>
  );
};
