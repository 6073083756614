import axios from 'axios';

export const baseURL = process.env.REACT_AWS_API_URL || 'https://crzgallery.s3.us-east-2.amazonaws.com';

export const photosUrl = axios.create({
  baseURL: 'https://www.googleapis.com/storage/v1'
});

export const photosUrlCrzServer = axios.create({
  baseURL: 'https://www.crzserver.com.br'
});

export const awsImageUpload = axios.create({
  baseURL: baseURL
});