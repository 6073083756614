import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDq6gC0j9iMOC-6-sJv0xJBP03kXwVASmc",
  authDomain: "crzapp-8e566.firebaseapp.com",
  projectId: "crzapp-8e566",
  storageBucket: "crzapp-8e566.appspot.com",
  messagingSenderId: "46290521826",
  appId: "1:46290521826:web:c1247b33c82b6bb2d418bb",
  measurementId: "G-B1LFQ055D6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
