import { useState, useEffect } from 'react';
import { FaTwitter } from 'react-icons/fa';
import { AiFillInstagram, AiFillMail } from 'react-icons/ai';
import { AuthorType } from '../../Types/BlogPosts';

type ProfileCardType = {
  profile: AuthorType;
  content: any;
};

export const ProfileCard = ({ profile, content }: ProfileCardType) => {
  const [showInsta, setShowInsta] = useState(false);
  const [showTwitter, setShowTwitter] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const placeHolderPhoto = 'https://res.cloudinary.com/dognkye6x/image/upload/v1674416777/crznews-removebg_t2pgdw.png';

  useEffect(() => {
    setShowInsta(Boolean(profile?.instagram));
    setShowTwitter(Boolean(profile?.twitter));
    setShowEmail(Boolean(profile?.email));
  }, [profile]);

  return (
    <div className="shadow-md rounded-lg p-4 mt-8">
      <div className="flex flex-row items-center justify-between">
        <div className="w-20 h-20 rounded-full overflow-hidden">
          <img 
            className="w-full h-full object-cover" 
            src={profile.photo ? profile.photo : placeHolderPhoto} 
            alt="Profile" 
          />
        </div>
        <div className="flex flex-col text-sm text-primary">
          <p className="text-light text-xl">
            {profile.nome?.toUpperCase() || 'Crz News Blog'}
          </p>
          {showInsta && (
            <a 
              href={`https://www.instagram.com/${profile?.instagram}`} 
              className="flex items-center mt-1 hover:text-secondary " 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <AiFillInstagram className="mr-1" />
              <p>@{profile?.instagram}</p>
            </a>
          )}
          {showTwitter && (
            <a 
              href={`https://twitter.com/${profile?.twitter}`} 
              className="flex items-center mt-1 hover:text-secondary" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <FaTwitter className="mr-1" />
              <p>@{profile?.twitter}</p>
            </a>
          )}
          {showEmail && (
            <div className="flex items-center mt-1 hover:text-secondary">
              <AiFillMail className="mr-1" />
              <p>{profile?.email}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
