
import React from 'react'
import { Link } from 'react-router-dom'

export const DownloadApp = () => {
	return (
		<div className='text-center bg-primary text-white p-6'>
			<div>
				<h1 className='sm:text-5xl text-2xl text-lighter rounded p-2'>Baixe o App <b>CRZ NEWS</b></h1>
			</div>

			<div className='grid grid-cols-1 md:grid-cols-2 p-4 m-2'>
				<div className='flex flex-col p-8 text-primary'>
					<div className='flex justify-start mb-20'>
						<h1 className='sm:text-5xl text-2xl text-secondary  rounded p-2'>iOS Download</h1>
					</div>

					<div className='flex flex-col text-lighter items-start'>
						<h3 className='text-secondary italic'>Usuários de iOS</h3>
						<p className='text-lg text-left mt-5'>
							Faça parte da comunidade de torcedores que acompanham o Cruzeiro. O <b className='text-lighter rounded p-1'>CRZ NEWS</b> disponibiliza o applicativo para download em iPhone ou iPad. Para fazer o download do app, basta clicar no botão abaixo.
						</p>
						<button className='p-4 rounded mt-10 bg-white hover:bg-secondary hover:text-secondary'>
							<a className="text-primary" href='https://apps.apple.com/br/app/crz-news/id1672761189?l=en' target="_blank" rel="noreferrer">
								Download para iOS
							</a>
						</button>
					</div>
				</div>

				<div className='flex flex-col p-8'>
					<div className='flex justify-start mb-20'>
						<h1 className='sm:text-5xl text-2xl text-secondary rounded p-2'>Android Download</h1>
					</div>

					<div className='flex flex-col text-lighter items-start'>
						<h3 className='text-secondary italic'>Usuários de Android</h3>
						<p className='text-lg text-left mt-5'>
							Não fique de fora! Tabela, jogos, notícias, vídeos e muito mais. O<b className='text-lighter rounded p-1'>CRZ NEWS</b> está disponível para download em dispositivos que usem Android. Seja celular ou tablet.
						</p>

						<button className='p-4 rounded mt-10 bg-white hover:bg-secondary hover:text-secondary'>
							<a className="text-primary" href='https://play.google.com/store/apps/details?id=com.dancosta.crznews' target="_blank" rel="noreferrer">
								Download para Android
							</a>
						</button>
					</div>
				</div>

			</div>
				<p className='text-lg text-center mt-10'>
					Muito obrigado pelo interesse! Esperamos que goste do aplicativo e que ele possa lhe ajudar a ficar mais informado sobre o <b className='text-secondary'>Cruzeiro</b>!
				</p>
		</div>
	)
}
